/* You can add global styles to this file, and also import other style files */
.separated-v {
  margin-bottom: 5px;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #9b9b9b;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #9b9b9b;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #9b9b9b;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #9b9b9b;
}
.blue {
  color: #32b4e5;
}
.fg-white {
  color: #ffffff;
}
.spacer {
  flex: 1 1 auto;
}
button.compact {
  padding: 6px;
}
.clickable {
  border: 1px solid transparent;
}
.clickable:hover {
  border-color: #32b4e5;
}
.rounded-rect {
  border-radius: 6px !important;
}
form {
  padding: 12px;
  width: 600px;
}
form .field-group {
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  align-content: stretch;
  margin-bottom: 12px;
}
form .field-group.offset {
  margin-left: 162px;
}
form .field-group label {
  font-size: 13px;
  flex: 0 0 auto;
}
form .field-group label.leading {
  width: 150px;
  text-align: right;
  padding-right: 12px;
}
form .field-group label.inline {
  text-align: left;
  width: auto;
}
form .field-group input:disabled {
  background-color: #dcdee0 !important;
}
form .field-group select:disabled {
  background-color: #dcdee0 !important;
}
form .field-group input,
form .field-group .parameter-input input {
  color: #2a2b2c !important;
  margin: 0;
  background-color: #ffffff !important;
  margin-left: 12px;
  border-style: solid !important;
  border-color: #9b9b9b !important;
}
form .field-group input[type=text],
form .field-group .parameter-input input[type=text],
form .field-group textarea,
form .field-group .parameter-input textarea,
form .field-group input[type=email],
form .field-group .paramter-input input[type=email] {
  width: 400px;
}
form .field-group select,
form .field-group .parameter-input select {
  color: #2a2b2c !important;
  background-color: #ffffff !important;
  margin-left: 12px;
  margin-bottom: 0 !important;
  width: auto !important;
  border-radius: 6px !important;
  border-style: solid !important;
  border-color: #9b9b9b !important;
}
form .field-group textarea {
  padding: 6px;
  border-radius: 6px !important;
  border-style: solid !important;
  border-color: #9b9b9b !important;
}
form .field-group label.for-textarea {
  margin-top: 6px;
  margin-right: 12px;
  align-self: flex-start;
}
form .field-group .inline-fields {
  display: flex;
  flex-flow: row nowrap;
  align-content: stretch;
  align-items: baseline;
}
form .field-group .inline-fields > * {
  flex: 0 0 auto;
  margin-left: 12px;
}
form .field-group .inline-fields input[type=radio],
form .field-group .inline-fields input[type=checkbox] {
  width: auto;
  align-self: center;
}
form .field-group .inline-fields input[type=number] {
  width: 60px;
}
form .field-group .inline-fields input[type=datetime],
form .field-group .inline-fields input[type=date],
form .field-group .inline-fields input[type=checkbox],
form .field-group .inline-fields input[type=time] {
  width: auto;
}
form .field-group .inline-fields input[type=text],
form .field-group .inline-fields textarea {
  width: auto;
}
form .field-group .inline-fields label {
  width: auto;
}
textarea {
  display: block;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
input[type=text].wide,
textarea.wide {
  width: 400px !important;
}
input[type=text].very-wide,
textarea.very-wide {
  width: 600px !important;
}
select {
  border: 1px solid #9b9b9b !important;
}
select.transparent {
  background-color: transparent !important;
  color: #2a2b2c !important;
}
p {
  margin: 12px 0;
}
p,
label {
  color: #2a2b2c;
}
a {
  color: #32b4e5;
}
.centered {
  text-align: center !important;
}
.icon.x-small {
  width: 13px;
  height: 13px;
}
.icon.xx-small {
  width: 9px;
  height: 9px;
}
.block-button.primary {
  background-color: #32b4e5 !important;
  color: #ffffff;
}
.grey {
  color: #9b9b9b;
}
.row.dark {
  background: #2a2b2c !important;
}
.row.dark .text h5,
.row.dark .text h6 {
  font-weight: 300;
  color: #dcdee0 !important;
}
.row.dark .text p {
  color: #9b9b9b !important;
  margin: 0 !important;
}
.row.dark .title {
  font-weight: 300;
  color: #dcdee0 !important;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.row.dark .subtitle {
  color: #9b9b9b !important;
  margin: 0 !important;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.row.dark .badge {
  color: #dcdee0;
  border: 1px solid #2a2b2c;
  padding: 4px;
  border-radius: 50%;
}
.help-text {
  font-weight: 300;
  font-size: 10pt;
  margin: 12px;
  padding: 0;
}
.word-wrap {
  white-space: pre-wrap;
  word-wrap: break-word;
}
.text .title {
  margin: 0;
}
.text .subtitle {
  margin: 0;
  font-size: 10pt;
  color: #9b9b9b;
}
header {
  padding: 12px 24px !important;
}
header.bordered {
  border-bottom: 1px solid #9b9b9b;
}
header.transparent {
  background: transparent !important;
}
button.large {
  padding: 16px 32px;
  font-size: 15px;
  font-weight: normal;
}
header a.back {
  font-size: 13px;
  color: #32b4e5;
  margin-right: 18px;
}
header a.back:hover {
  cursor: pointer;
}
header nav a {
  color: inherit;
}
header h6 {
  margin-left: 6px !important;
}
select {
  background: transparent;
}
button.disabled {
  color: #9b9b9b !important;
  background: #dcdee0 !important;
  border: 1px #9b9b9b solid !important;
}
.flex-row {
  display: flex;
  flex-direction: row;
  align-content: stretch;
  align-items: center;
}
.flex-row.nowrap {
  flex-wrap: nowrap;
}
.flex-row.wrap {
  flex-wrap: wrap;
}
.flex-row.wrap.reverse {
  flex-wrap: wrap-reverse;
}
.flex-row > * {
  flex: 0 0 auto;
  margin-right: 12px;
}
.flex-row > .grow {
  flex: 1 1 auto;
}
.flex-row.split > * {
  flex-basis: 50%;
}
.flex-row.justify-left {
  justify-items: flex-start;
}
.flex-row.justify-right {
  justify-items: flex-end;
}
.flex-row.transparent {
  background: transparent !important;
}
.flex-row.justify-center {
  justify-content: center;
  justify-items: center;
}
.flex-col {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  align-items: flex-start;
}
.flex-col.align-center {
  align-items: center;
}
.flex-col.nowrap {
  flex-wrap: nowrap;
}
.flex-col.wrap {
  flex-wrap: wrap;
}
.flex-col.wrap.reverse {
  flex-wrap: wrap-reverse;
}
.flex-col > * {
  flex: 0 0 auto;
}
.flex-col > .grow {
  flex: 1 1 auto;
}
.flex-col > * {
  flex: 0 0 auto;
}
.flex-col.split > * {
  flex-basis: 50%;
}
.flex-col > .grow {
  flex: 1 1 auto;
}
.flex-col.justify-left {
  justify-items: flex-start;
}
.flex-col.justify-right {
  justify-items: flex-end;
}
.flex-col.transparent {
  background: transparent !important;
}
.flex-col.justify-center {
  justify-content: center;
  justify-items: center;
}
.dark h3,
.dark p {
  color: #dcdee0;
}
.padded {
  padding: 12px;
}
.mycsstest {
  color: red !important;
}
.search {
  display: flex;
  flex-flow: row nowrap;
  align-content: stretch;
  align-items: center;
  margin-right: 12px;
}
.search .icon {
  position: relative;
  left: 38px;
}
.search input {
  margin: 0;
  height: 27px;
  padding: 3px;
  font-size: 14px;
  border-radius: 3px;
  border: 1px solid #9b9b9b;
  padding-left: 24px;
}
