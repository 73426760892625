@import url('../../../../../libs/assets/src/assets/styles/colors.less');
.separated-v {
  margin-bottom: 5px;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: @medium-grey;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: @medium-grey;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: @medium-grey;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: @medium-grey;
}

.blue {
  color: @blue;
}

.fg-white {
  color: @white;
}

.spacer {
  flex: 1 1 auto;
}

button.compact {
  padding: 6px;
}

.clickable {
  border: 1px solid transparent;
}

.clickable:hover {
  border-color: @blue;
}

.rounded-rect {
  border-radius: 6px !important;
}

form {
  padding: 12px;
  width: 600px;
  .field-group {
    display: flex;
    flex-flow: row nowrap;
    align-items: baseline;
    align-content: stretch;
    margin-bottom: 12px;
    &.offset {
      margin-left: 162px;
    }
    label {
      font-size: 13px;
      flex: 0 0 auto;
    }
    label.leading {
      width: 150px;
      text-align: right;
      padding-right: 12px;
    }
    label.inline {
      text-align: left;
      width: auto;
    }
    input:disabled {
      background-color: @light-grey !important;
    }
    select:disabled {
      background-color: @light-grey !important;
    }
    input, .parameter-input input {
      color: @darker-grey !important;
      margin: 0;
      background-color: @white !important;
      margin-left: 12px;
      border-style: solid !important;
      border-color: @medium-grey !important;
    }
    input[type=text], .parameter-input input[type=text], textarea, .parameter-input textarea, input[type=email], .paramter-input input[type=email] {
      width: 400px;
    }
    select, .parameter-input select {
      // -webkit-appearance: none;
      color: @darker-grey !important;
      background-color: @white !important;
      margin-left: 12px;
      margin-bottom: 0 !important;
      width: auto !important;
      border-radius: 6px !important;
      border-style: solid !important;
      border-color: @medium-grey !important;
    }
    textarea {
      padding: 6px;
      border-radius: 6px !important;
      border-style: solid !important;
      border-color: @medium-grey !important;
    }
    label.for-textarea {
      margin-top: 6px;
      margin-right: 12px;
      align-self: flex-start;
    }
    .inline-fields {
      display: flex;
      flex-flow: row nowrap;
      align-content: stretch;
      align-items: baseline;
      & > * {
        flex: 0 0 auto;
        margin-left: 12px;
      }
      input[type=radio], input[type=checkbox] {
        width: auto;
        align-self: center;
      }
      input[type=number] {
        width: 60px;
      }
      input[type=datetime], input[type=date], input[type=checkbox], input[type=time] {
        width: auto;
      }
      input[type=text], textarea {
        width: auto;
      }
      label {
        width: auto;
      }
    }
  }
}

textarea {
  display: block;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

input[type=text].wide, textarea.wide {
  width: 400px !important;
}

input[type=text].very-wide, textarea.very-wide {
  width: 600px !important;
}

select {
  border: 1px solid @medium-grey !important;
  &.transparent {
    background-color: transparent !important;
    color: @darker-grey !important;
  }
}

p {
  margin: 12px 0;
}

p, label {
  color: @darker-grey;
}

a {
  color: @blue;
}

.centered {
  text-align: center !important;
}

.icon {
  &.x-small {
    width: 13px;
    height: 13px;
  }
  &.xx-small {
    width: 9px;
    height: 9px;
  }
}

.block-button.primary {
  background-color: @blue !important;
  color: @white;
}

.grey {
  color: @medium-grey;
}

.row {
  &.dark {
    background: @darker-grey !important;
    .text {
      h5, h6 {
        font-weight: 300;
        color: @light-grey !important;
      }
      p {
        color: @medium-grey !important;
        margin: 0 !important;
      }
    }
    .title {
      font-weight: 300;
      color: @light-grey !important;
      word-wrap: break-word;
      white-space: pre-wrap;
    }
    .subtitle {
      color: @medium-grey !important;
      margin: 0 !important;
      word-wrap: break-word;
      white-space: pre-wrap;
    }
    .badge {
      color: @light-grey;
      border: 1px solid @darker-grey;
      padding: 4px;
      border-radius: 50%;
    }
  }
}

.help-text {
  font-weight: 300;
  font-size: 10pt;
  margin: 12px;
  padding: 0;
}

.word-wrap {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.text {
  .title {
    margin: 0;
  }
  .subtitle {
    margin: 0;
    font-size: 10pt;
    color: @medium-grey;
  }
}

header {
  padding: 12px 24px !important;
  &.bordered {
    border-bottom: 1px solid @medium-grey;
  }
  &.transparent {
    background: transparent !important;
  }
}

button {
  &.large {
    padding: 16px 32px;
    font-size: 15px;
    font-weight: normal;
  }
}

header {
  a {
    &.back {
      font-size: 13px;
      color: @blue;
      margin-right: 18px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  nav {
    a {
      color: inherit;
    }
  }
  h6 {
    margin-left: 6px !important;
  }
}

select {
  background: transparent;
}

button.disabled {
  color: @medium-grey !important;
  background: @light-grey !important;
  border: 1px @medium-grey solid !important;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-content: stretch;
  align-items: center;
  &.nowrap {
    flex-wrap: nowrap;
  }
  &.wrap {
    flex-wrap: wrap;
    &.reverse {
      flex-wrap: wrap-reverse;
    }
  }
  &>* {
    flex: 0 0 auto;
    margin-right: 12px;
  }
  &>.grow {
    flex: 1 1 auto;
  }
  &.split > * {
    flex-basis: 50%;
  }
  &.justify-left {
    justify-items: flex-start;
  }
  &.justify-right {
    justify-items: flex-end;
  }
  &.transparent {
    background: transparent !important;
  }
  &.justify-center {
    justify-content: center;
    justify-items: center;
  }
}

.flex-col {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  align-items: flex-start;
  &.align-center {
    align-items: center;
  }
  &.nowrap {
    flex-wrap: nowrap;
  }
  &.wrap {
    flex-wrap: wrap;
    &.reverse {
      flex-wrap: wrap-reverse;
    }
  }
  &>* {
    flex: 0 0 auto;
  }
  &>.grow {
    flex: 1 1 auto;
  }
  &>* {
    flex: 0 0 auto;
  }
  &.split > * {
    flex-basis: 50%;
  }
  &>.grow {
    flex: 1 1 auto;
  }
  &.justify-left {
    justify-items: flex-start;
  }
  &.justify-right {
    justify-items: flex-end;
  }
  &.transparent {
    background: transparent !important;
  }
  &.justify-center {
    justify-content: center;
    justify-items: center;
  }
}

.dark {
  h3, p {
    color: @light-grey;
  }
}

.padded {
  padding: 12px;
}

.mycsstest {
  color: red !important;
}

.search {
  display: flex;
  flex-flow: row nowrap;
  align-content: stretch;
  align-items: center;
  margin-right: 12px;
  .icon {
    position: relative;
    left: 38px;
  }
  input {
    margin: 0;
    height: 27px;
    padding: 3px;
    font-size: 14px;
    border-radius: 3px;
    border: 1px solid @medium-grey;
    padding-left: 24px;
  }
}
